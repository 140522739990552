import React from 'react'
import { Link, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Superscript from '../components/Superscript'
import { rhythm } from '../utils/typography'

const shadow = `1px 1px #ccc, 2px 2px #ccc, 3px 3px #ccc, 4px 4px #ccc, 5px 5px #ccc,
6px 6px #777, 7px 7px #777, 8px 8px #777, 9px 9px #777, 10px 10px #777`

const mobileshadow = `0.1vw 0.1vw #ccc, 0.2vw 0.2vw #ccc, 0.3vw 0.3vw #ccc, 0.4vw 0.4vw #ccc, 0.5vw 0.5vw #ccc,
0.6vw 0.6vw #777, 0.7vw 0.7vw #777, 0.8vw 0.8vw #777, 0.9vw 0.9vw #777, 1vw 1vw #777`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Writing"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Superscript
          shadow={shadow}
          mobileshadow={mobileshadow}
          style={{ color: '#e1e1e1' }}
        >
          Writing.
        </Superscript>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Post key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link
                  style={{ boxShadow: `none`, textDecoration: 'none' }}
                  to={`writing` + node.fields.slug.replace(/\/$/, ``)}
                >
                  {title}
                </Link>
              </h3>
              <Small>PUBLISHED ON {node.frontmatter.date.toUpperCase()}</Small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </Post>
          )
        })}{' '}
      </Layout>
    )
  }
}

const Post = styled.div`
  padding-top: 0px;
  h3 {
    a {
      color: blue;
      :active {
        color: blue;
      }
    }
  }
`

const Small = styled.div`
  font-size: 10px;
  letter-spacing: 1px;
  color: rgb(141, 141, 141);
  font-family: Inter, sans-serif;
  sup {
    font-size: 12px !important;
    top: 0;
  }
`

const Header = styled.div`
  margin-bottom: 24px;
  h1 {
    font-size: 10vw;
    color: white;
    font-family: Montserrat;
    font-weight: 800;
    text-align: center;
    letter-spacing: 2vw;
  }
  a {
    font-size: 14px;
    border-bottom: none;
    text-decoration: none;
    box-shadow: none;
  }
`

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
