import React from 'react'
import { Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import { rhythm, scale } from '../utils/typography'
import { runInDebugContext } from 'vm'

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
body {
  transition: all 600ms ease-in-out;
  background-color: #fff;
}`

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <WritingContainer>
        <GlobalStyle />
        <WritingWrapper>
          <WritingInner>
            <Nav>
              <Logo to="/">Karl Danninger</Logo>
              <Links>
                <Link to="/writing">Articles</Link>
                <Link to="/about">About</Link>
              </Links>
            </Nav>
            <WritingContent id="Writing">{children}</WritingContent>
          </WritingInner>
        </WritingWrapper>
      </WritingContainer>
    )
  }
}

const WritingContent = styled.div``

const Nav = styled.div`
  font-family: Inter, sans-serif;
  font-size: 16px;
  margin-bottom: 50px;
`

const WritingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const WritingWrapper = styled.div`
  padding: 24px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  min-height: 100%;
  flex: auto;
  @media (max-width: 1000px) {
    padding: 2.4vw;
  }
  @media (max-width: 680px) {
    padding: 4.8vw;
  }
`

const WritingInner = styled.div`
  max-width: 900px;
  font-size: 24px;
  @media (max-width: 680px) {
    font-size: 18px;
  }
  h3 {
    font-size: 48px;
    font-family: Inter;
    font-weight: 900;
    margin-top: 48px;
    @media (max-width: 680px) {
      font-size: 32px;
    }
  }
`

const Logo = styled(Link)`
  line-height: 75px;
  border-bottom: none !important;
  font-family: Inter, sans-serif;
  font-weight: 900;
  color: #333 !important;
  font-weight: 900;
  text-decoration: none;
  @media (max-width: 680px) {
    line-height: 50px;
    font-size: 14px;
  }
`

const Links = styled.div`
  float: right;
  a {
    line-height: 75px;
    border-bottom: none !important;
    font-family: Inter, sans-serif;
    color: rgb(141, 141, 141) !important;
    font-weight: 600;
    margin-left: 36px;
    text-decoration: none;
    @media (max-width: 680px) {
      line-height: 50px;
      font-size: 14px;
      margin-left: 24px;
    }
  }
`

const LinksContainer = styled.div`
  text-align: right;
  justify-content: center;
  height: 100%;
  align-items: center;
`

export default Layout
